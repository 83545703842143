import { React, Component } from "react";
import axios from 'axios';
import { odataUrl } from '../util/apiUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import S3 from 'react-aws-s3';
import { bucket, blogDir, region, accessId, secretKey } from '../util/vars';


const config = {
    bucketName: bucket,
    dirName: blogDir,
    region: region,
    accessKeyId: accessId,
    secretAccessKey: secretKey,
}


class BlogComposer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            description1: "",
            description2: "",
            selectedImageFile: null,
            image: ""
        };

        this.handle = this.handle.bind(this);
        this.upload = this.upload.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.uploadToS3 = this.uploadToS3.bind(this);
        this.submitData = this.submitData.bind(this);
    }

    handle(e) {
        const id = e.target.id;
        const value = e.target.value;
        this.setState({
            [id]: value
        });
    }

    upload(e) {
        const id = e.target.id;
        const file = e.target.files[0];
        const value = file.name
        if (!value) {
            toast.error('Image is required!', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        if (!value.match(/\.(jpg|jpeg|png)$/)) {
            toast.error('Select a valid image!', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        this.setState({
            [id]: value,
            ["selectedImageFile"] : e.target.files[0]
        });
    }

    uploadToS3(e) {
        const ReactS3Client = new S3(config);
        ReactS3Client
        .uploadFile(this.state.selectedImageFile)
        .then((data) => {
            this.setState({
                ["image"]: data.key
            }, () => {
                this.submitData();
            });
            toast.success('Image uploaded!', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        })
        .catch((err) => {
            toast.error('Error! Not able to upload image file', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    }

    submitData() {
        var headers = { 
            'Authorization': 'Bearer ' + localStorage.getItem('JWT_TOKEN'), 
            'Content-Type': 'application/json'
        }
        if (
            this.state.title.length == 0 ||
            this.state.description1.length == 0 ||
            this.state.image.length == 0) {
            toast.error('Error! All fields are required', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        axios.post(odataUrl + 'Blogs',
        JSON.stringify({
            Title: this.state.title,
            Description1: this.state.description1,
            Description2: this.state.description2,
            ImageName: this.state.image
        }), 
        {headers: headers}
        )
        .then((response) => {
            console.log(response);
            //handle success
            window.location.href = '/';
        })
        .catch((error) => {
            //handle error
            toast.error('Invalid data submitted, please try again', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.log(error);
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.uploadToS3();
    }

    render() {
        return (
            <div className="container mt-5">
                <h2 className="mb-3">Blog Composer</h2>
                <form onSubmit={this.handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="title">
                        Title
                        </label>
                        <input className="form-control" type="text" id="title" value = {this.state.title} onChange={this.handle} required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="description1">
                        Description 1
                        </label>
                        <textarea rows="5" cols="40" className="form-control" id="description1" value = {this.state.description1} onChange={this.handle} required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="description2">
                        Description 2 (optional)
                        </label>
                        <textarea rows="5" cols="40" className="form-control" id="description2" value = {this.state.description2} onChange={this.handle} required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="image">
                        Insert Image
                        </label>
                        <input type="file" id = "image" accept="image/*" onChange={this.upload} required/>
                    </div>
                    <br />
                    <button className="btn btn-danger" type="submit">
                        Submit
                    </button>
                </form>
                <ToastContainer />
            </div>
        );
    }
}
export default BlogComposer;