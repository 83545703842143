import React from 'react';
import {
    GridComponent, ColumnsDirective, ColumnDirective, Page, Inject, Filter, Group,
    Edit, EditSettingsModel, Toolbar, ExcelExport
} from '@syncfusion/ej2-react-grids';
import { DataManager, ODataV4Adaptor } from '@syncfusion/ej2-data';
import { odataUrl } from '../util/apiUrl';
import { getCurrentDate } from '../util/date';

const AnswersGrid = () => {
    const editOptions = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Batch' };
    const toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel', 'ExcelExport'];
    const filterOption = { ignoreAccent: true, type: 'Menu' };

    const data = new DataManager({
        adaptor: new ODataV4Adaptor(),
        headers: [{ 'Authorization': 'Bearer ' + localStorage.getItem('JWT_TOKEN') }],
        url: odataUrl + 'Answers',
    })

    var grid;
    const toolbarClick = (args) => {
        if (grid && args.item.id === 'Grid_excelexport') {
            const excelExportProperties = {
                exportType: 'AllPages'
            };
            grid.excelExport(excelExportProperties);
        }
    }


    return (
        <div className='control-pane' style={{ marginTop: '2%' }}>
            <div className='control-section'>
                <GridComponent id="Grid"
                    dataSource={data}
                    allowPaging={true}
                    pageSettings={{ pageSize: 100 }}
                    allowFiltering={true}
                    allowGrouping={true}
                    toolbar={toolbarOptions}
                    editSettings={editOptions}
                    filterSettings={filterOption}
                    allowExcelExport={true}
                    toolbarClick={toolbarClick}
                    ref={g => grid = g}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='Id' isIdentity={true} visible={false} isPrimaryKey="true" headerText='Id' width='120' textAlign='Right'></ColumnDirective>
                        <ColumnDirective field='AnswerCode' headerText='Answer Code' width='160'></ColumnDirective>
                        <ColumnDirective field='AnswerText' headerText='Answer Text' width='160'></ColumnDirective>
                        <ColumnDirective field='RangeBelow' headerText='Range Below' width='160'></ColumnDirective>
                        <ColumnDirective field='CLB_Eqv' headerText='CLB_Eqv' width='160' ></ColumnDirective>
                        <ColumnDirective field='CRS_Married' headerText='CRS_Married' width='160' ></ColumnDirective>
                        <ColumnDirective field='CRS_Single' headerText='CRS_Married' width='160' ></ColumnDirective>
                        <ColumnDirective field='CRS_Co_applicant' headerText='CRS_Married' width='160' ></ColumnDirective>
                        <ColumnDirective field='QuestionCode' headerText='CRS_Married' width='160' ></ColumnDirective>


                        <ColumnDirective field='CreatedByUser' visible={false} width='160' defaultValue={1} allowEditing={false}></ColumnDirective>
                        <ColumnDirective field='IsDeleted' visible={false} width='160' defaultValue={0} allowEditing={false}></ColumnDirective>
                        <ColumnDirective field='CreatedDate' width='160' defaultValue={getCurrentDate()} editType='datepickeredit' allowEditing={false}></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Page, Filter, Toolbar, Edit, ExcelExport]} />
                </GridComponent>
            </div>
            <div id='waitingpopup' className='waitingpopup'>
                <span id='gif' className='image'></span>
            </div>
        </div>
    );
}

export default AnswersGrid;
