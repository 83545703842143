import './App.css';
import { Switch, Route, BrowserRouter as Router, Redirect, } from 'react-router-dom';

import Login from './pages/login';
import GridPage from './pages/gridPage';
import NotificationComposer from './pages/notificationComposerPage';
import BlogComposer from './pages/blogComposerPage';
import NewsFeedComposer from './pages/newsFeedComposerPage';


function App() {


  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route {...rest} render={(props) => localStorage.getItem('JWT_TOKEN') ? <Component {...props} />
        : <Redirect to={{ pathname: '/login' }} />}
      />
    )
  }

  return (
    <Router>
      <Switch>
        <Route path='/login' component={Login} />
        <PrivateRoute path='/createNotification' component={NotificationComposer} />
        <PrivateRoute path='/createBlog' component={BlogComposer} />
        <PrivateRoute path='/createNewsFeed' component={NewsFeedComposer} />
        <PrivateRoute path='/' component={GridPage} />
      </Switch>

    </Router>
  );
}

export default App;
