import React from 'react';
import {
    GridComponent, ColumnsDirective, ColumnDirective, Page, Inject, Filter,
    Edit, Toolbar, ExcelExport
} from '@syncfusion/ej2-react-grids';
import { DataManager, ODataV4Adaptor } from '@syncfusion/ej2-data';
import { odataUrl } from '../util/apiUrl';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { Link } from "react-router-dom";

const NotificationsGrid = () => {
    const editOptions = { allowEditing: true, allowDeleting: true, mode: 'Batch' };
    const toolbarOptions = ['Edit', 'Delete', 'Update', 'Cancel', 'ExcelExport'];
    const filterOption = { ignoreAccent: true, type: 'Menu' };

    const data = new DataManager({
        adaptor: new ODataV4Adaptor(),
        headers: [{ 'Authorization': 'Bearer ' + localStorage.getItem('JWT_TOKEN') }],
        url: odataUrl + 'Notifications',
    })

    var grid;
    const toolbarClick = (args) => {
        if (grid && args.item.id === 'Grid_excelexport') {
            const excelExportProperties = {
                exportType: 'AllPages'
            };
            grid.excelExport(excelExportProperties);
        }
    }
    return (
        <div className='control-pane' style={{ marginTop: '2%' }}>
            <div className='control-section'>
                <Link to="/createNotification">
                    <ButtonComponent isPrimary>Add</ButtonComponent>
                </Link>
                <GridComponent id="Grid"
                    dataSource={data}
                    allowPaging={true}
                    pageSettings={{ pageSize: 100 }}
                    allowFiltering={true}
                    allowGrouping={true}
                    toolbar={toolbarOptions}
                    editSettings={editOptions}
                    filterSettings={filterOption}
                    allowExcelExport={true}
                    toolbarClick={toolbarClick}
                    ref={g => grid = g}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='Id' isIdentity={true} visible={false} isPrimaryKey="true" headerText='Id' width='120' textAlign='Right'></ColumnDirective>
                        <ColumnDirective field='Title' headerText='Title' width='160'></ColumnDirective>
                        <ColumnDirective field='Content' headerText='Content' width='160'></ColumnDirective>
                        <ColumnDirective field='Recommendation' headerText='Recommendation' width='160'></ColumnDirective>
                        <ColumnDirective field='ProgramType' headerText='ProgramType' width='160'></ColumnDirective>
                        <ColumnDirective field='Codes' headerText='Codes' width='160'></ColumnDirective>
                        <ColumnDirective field='RedirectPath' headerText='RedirectPath' width='160'></ColumnDirective>
                        <ColumnDirective field='CreatedDate' headerText='CreatedDate' width='160' allowEditing={false}></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Page, Filter, Toolbar, Edit, ExcelExport]} />
                </GridComponent>
            </div>
            <div id='waitingpopup' className='waitingpopup'>
                <span id='gif' className='image'></span>
            </div>
        </div>
    );
}

export default NotificationsGrid;
