import { React, Component } from "react";
import axios from 'axios';
import { odataUrl } from '../util/apiUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class NewsFeedComposer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            description1: "",
            description2: "",
        };

        this.handle = this.handle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handle(e) {
        const id = e.target.id;
        const value = e.target.value;
        this.setState({
            [id]: value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        console.log(this.state);
        var headers = { 
            'Authorization': 'Bearer ' + localStorage.getItem('JWT_TOKEN'), 
            'Content-Type': 'application/json'
        }
        if (
            this.state.title.length == 0 ||
            this.state.description1.length == 0) {
            toast.error('Error! All fields are required', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        axios.post(odataUrl + 'Newsfeeds',
        JSON.stringify({
            Title: this.state.title,
            Description1: this.state.description1,
            Description2: this.state.description2
        }), 
        {headers: headers}
        )
        .then((response) => {
            console.log(response);
            //handle success
            window.location.href = '/';
        })
        .catch((error) => {
            //handle error
            toast.error('Invalid data submitted, please try again', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.log(error);
        });
    }

    render() {
        return (
            <div className="container mt-5">
                <h2 className="mb-3">Newsfeed Composer</h2>
                <form onSubmit={this.handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="title">
                        Title
                        </label>
                        <input className="form-control" type="text" id="title" value = {this.state.title} onChange={this.handle} required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="description1">
                        Description 1
                        </label>
                        <textarea rows="5" cols="40" className="form-control" id="description1" value = {this.state.description1} onChange={this.handle} required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="description2">
                        Description 2 (optional)
                        </label>
                        <textarea rows="5" cols="40" className="form-control" id="description2" value = {this.state.description2} onChange={this.handle} required />
                    </div>
                    <br />
                    <button className="btn btn-danger" type="submit">
                        Submit
                    </button>
                </form>
                <ToastContainer />
            </div>
        );
    }
}
export default NewsFeedComposer;