import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { authUrl } from '../util/apiUrl';
import '../App.css';

export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const validateForm = () => {
        return username.length > 0 && password.length > 0;
    }

    const handleLogin = (event) => {
        event.preventDefault();
        axios.post(authUrl + '/admin', {
            Username: username,
            Password: password
        })
            .then((response) => {
                //handle success
                localStorage.setItem('JWT_TOKEN', response.data);
                window.location.href = '/';
                console.log(response);
            })
            .catch((response) => {
                //handle error
                toast.error('Invalid username or password, please try again', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });


    }

    return (
        <div className="Login" style={{ marginTop: '15%' }}>
            <Form >
                <Form.Group size="lg" >
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        autoFocus
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Form.Group>
                <Form.Group size="lg" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>
                <Button block size="lg" type="submit" disabled={!validateForm()} onClick={handleLogin}>
                    Login
                </Button>
                <ToastContainer />
            </Form>
        </div>
    );
}