import { React, useState, useEffect, Component } from "react";
import Select from 'react-select';
import axios from 'axios';
import { odataUrl } from '../util/apiUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class NotificationComposer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: [
                { value: 'CRS', label: 'CRS'},
                { value: 'Work Permit', label: 'Work Permit'},
                { value: 'Other Programs', label: 'Other Progams'}
            ],
            redirectPathOptions: [
                { value: '/payment', label: 'Payment Page'},
                { value: '/', label: 'Edit Questionnaire Page'},
                { value: '/question_recommendation', label: 'Results Page'},
                { value: '/news', label: 'News/Articles Page'},
                { value: '/resources', label: 'Resources Page'}
            ],
            crs: [],
            programs: [],
            crsData: [],
            workPermitData: [],
            programsData: [],
            data: [],
            selected: false,
            title: "",
            programType: "",
            description: "",
            recommendationMessage: "",
            selectedCodes: [],
            intSelectedCodes: [],
            redirectPath: ""
        };

        this.handle = this.handle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleProgramsCodes = this.handleProgramsCodes.bind(this);
    }

    componentDidMount() {
        var headers = { 'Authorization': 'Bearer ' + localStorage.getItem('JWT_TOKEN') }
        axios.get(odataUrl + 'CrsRecommendations', {headers: headers})
        .then(response => {
            this.setState({crs: response.data});
            const data = this.state.crs.value;
            const crsList = [];
            data.forEach((x) => {
                crsList.push({"value": x.Id, "label": x.Id + ' - '+ x.Description + ""});
            })
            this.setState({crsData: crsList});
        })
        .catch(error => {
            console.log(error);
        })

        axios.get(odataUrl + 'ProgramRecommendationsRules', {headers: headers})
        .then(response => {
            this.setState({programs: response.data})
            const data = this.state.programs.value;
            const programsList = [];
            const workPermitList = [];
            data.forEach((x) => {
                if (x.Category == 'Work-Permit')
                    workPermitList.push({"value": x.Id, "label": x.Id + ' - '+ x.ProgramName + ""});
                else
                    programsList.push({"value": x.Id, "label": x.Id + ' - '+ x.ProgramName + ""});
            })
            this.setState({programsData: programsList, workPermitData: workPermitList})
        })
        .catch(error => {
            console.log(error);
        })
    }

    onCodesChange = (value) => {
        let input = value['value'];
        if (input == 'CRS')
            this.setState({data: this.state.crsData});
        else if (input == 'Work Permit')
            this.setState({data: this.state.workPermitData})
        else
            this.setState({data: this.state.programsData});
        this.setState({programType: value['value']});
    };

    handleProgramsCodes(e) {
        const value = e;
        this.setState({
            ["selectedCodes"]: value
        });
        //resetting the array to empty before adding in updated elements
        this.setState({intSelectedCodes: []});
        e.forEach((x) => {
            let value = x["value"];
            this.setState((prevstate) => ({intSelectedCodes: prevstate.intSelectedCodes.concat([value])}))
        })
    }

    onRedirectPathChange = (value) => {
        this.setState({redirectPath: value['value']});
    };

    handle(e) {
        const id = e.target.id;
        const value = e.target.value;
        this.setState({
            [id]: value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        var headers = { 
            'Authorization': 'Bearer ' + localStorage.getItem('JWT_TOKEN'), 
            'Content-Type': 'application/json'
        }
        if (
            this.state.title.length == 0 ||
            this.state.description.length == 0 ||
            this.state.recommendationMessage.length == 0 ||
            this.state.programType.length == 0 || 
            this.state.intSelectedCodes.length == 0 ||
            this.state.redirectPath.length == 0) {
            toast.error('Error! All fields are required', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        axios.post(odataUrl + 'Notifications',
        JSON.stringify({
            Title: this.state.title,
            Content: this.state.description,
            Recommendation: this.state.recommendationMessage,
            ProgramType: this.state.programType,
            Codes: JSON.stringify(this.state.intSelectedCodes),
            RedirectPath: this.state.redirectPath,
            CreatedByUser: 1,
            LastModifiedByUser: 1
        }), 
        {headers: headers}
        )
        .then((response) => {
            console.log(response);
            //handle success
            window.location.href = '/';
        })
        .catch((error) => {
            //handle error
            toast.error('Invalid data submitted, please try again', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.log(error);
        });
    }

    render() {
        return (
            <div className="container mt-5">
                <h2 className="mb-3">Notification composer</h2>
                <form onSubmit={this.handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="title">
                        Title
                        </label>
                        <input className="form-control" type="text" id="title" value = {this.state.title} onChange={this.handle} required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="programType">
                        Program Type <span style={{color: 'red'}}><small>(Only one program per notification, do not mix the program codes)</small></span>
                        </label>
                        <Select id="programType" value={this.state.value} onChange={this.onCodesChange} options={this.state.options} required/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="description">
                        Description
                        </label>
                        <textarea className="form-control" id="description" value = {this.state.description} onChange={this.handle} required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="recommendationMessage">
                        Recommendation
                        </label>
                        <textarea className="form-control" id="recommendationMessage" value = {this.state.recommendationMessage} onChange={this.handle} required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="selectedCodes">
                        Select Your Codes
                        </label>
                        <Select id="selectedCodes" options={this.state.data} onChange={this.handleProgramsCodes} isMulti className="basic-multi-select" classNamePrefix="select" required/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="selectedRedirectPath">
                        Select Redirection Path
                        </label>
                        <Select id="selectedRedirectPath" value={this.state.value} options={this.state.redirectPathOptions} onChange={this.onRedirectPathChange} required/>
                    </div>
                    <br />
                    <button className="btn btn-danger" type="submit">
                        Submit
                    </button>
                </form>
                <ToastContainer />
            </div>
        );
    }
}
export default NotificationComposer;