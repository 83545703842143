import React from 'react';
import DrawGrid from '../components/drawGrid';
import ProfileGrid from '../components/profileGrid';
import ProgramRulesGrid from '../components/programRulesGrid';
import AnswersGrid from '../components/answersGrid';
import QuestionsGrid from '../components/questionsGrid';
import PartnersGrid from '../components/partnersGrid';
import PartnerClients from '../components/clientGrid';
import CrsRecommendationsGrid from '../components/crsRecommendationsGrid';
import LeadRankGrid from "../components/leadRankGrid";
import NotificationsGrid from '../components/notificationsGrid';
import BlogGrid from '../components/blogGrid';
import NewsFeedGrid from '../components/newsFeedGrid';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';

const GridPage = () => {
    const headers = [
        { text: "Immigration Profiles"},
        { text: "Program Rules" },
        { text: "Government Draws" },
        { text: "Questions" },
        { text: "Answers" },
        { text: "Partners" },
        { text: "Clients" },
        { text: "CRS Recommendations"},
        { text: "Lead Rank"},
        { text: "Notifications"},
        { text: "Blogs"},
        { text: "Newsfeeds"}
    ];

    const logOut = () => {
        localStorage.removeItem('JWT_TOKEN');
        window.location.href = '/';
    }

    return (
        <div className='control-pane' style={{ margin: '10%', marginTop: '2%' }}>
            <div className='control-section tab-control-section' >
                <TabComponent id='defaultTab' >
                    <TabItemsDirective>
                        <TabItemDirective header={headers[0]} content={ProfileGrid} />
                        <TabItemDirective header={headers[1]} content={ProgramRulesGrid} />
                        <TabItemDirective header={headers[2]} content={DrawGrid} />
                        <TabItemDirective header={headers[3]} content={QuestionsGrid} />
                        <TabItemDirective header={headers[4]} content={AnswersGrid} />
                        <TabItemDirective header={headers[5]} content={PartnersGrid} />
                        <TabItemDirective header={headers[6]} content={PartnerClients} />
                        <TabItemDirective header={headers[7]} content={CrsRecommendationsGrid} />
                        <TabItemDirective header={headers[8]} content={LeadRankGrid} />
                        <TabItemDirective header={headers[9]} content={NotificationsGrid} />
                        <TabItemDirective header={headers[10]} content={BlogGrid} />
                        <TabItemDirective header={headers[11]} content={NewsFeedGrid} />

                    </TabItemsDirective>

                </TabComponent>
                <div style={{ position: 'fixed', right: '3rem', top: '3rem' }}>
                  <ButtonComponent isPrimary onClick={() => logOut()}>LOG OUT</ButtonComponent>
                </div>
            </div>
        </div>
    )
}

export default GridPage;